import React from "react";

function HereToStay() {
  return (
    <section className="bg-gladior-dark p-8  text-white">
      <div className="container mx-auto px-6 py-10  max-w-7xl text-center">
        <p className="text-4xl font-bold ">#HereToStay</p>
        <p className="text-2xl mb-4">Blijven optimaliseren</p>
        <p>
          We hebben diverse meetinstrumenten en rapportages voorhanden met de
          diverse platformen. Dit levert waardevolle inzichten op in het
          gebruik. Zo hebben we kunnen zien dat bepaalde onderdelen van het
          intranet niet optimaal gebruikt werden. Onze vervolgactie was het
          faciliteren van een extra training. Op deze manier blijven we de
          interactie met, maar ook tussen de platformen optimaliseren.
        </p>
        <p className="text-2xl mt-4">Van GET naar GROW</p>
      </div>
    </section>
  );
}

export default HereToStay;
