import { graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import ContactForm from "../components/Contactformulier";
import { HTMLContent } from "../components/Content";
import Here2Stay from "../components/HereToStay";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const WebCasePageTemplate = ({ post }) => {
  return (
    <>
      <section className="bg-dark pb-16">
        <div className="container flex mx-auto px-6 py-10  max-w-7xl flex-col lg:flex-row">
          <div className="flex-1">
            <h1 className="text-white text-3xl font-bold mb-4">{post.title}</h1>
            <p className="text-xl text-white">{post.lead_paragraph}</p>
            <div className="flex mb-4 flex-wrap">
              <div className="w-full sm:w-1/2">
                <p className=" sm:text-lg md:text-base xl:text-base  mb-4 p-4">
                  <p className="text-gladior-pink"> Diensten</p>
                  <ReactMarkdown
                    className="netlify-html text-white "
                    source={post.services.split(`](assets`).join(`](/assets`)}
                  />
                </p>
              </div>
              <div className="w-full sm:w-1/2">
                <p className=" sm:text-lg md:text-base xl:text-base  mb-4 p-4">
                  <p className="text-gladior-pink">Over {post.id}</p>
                  <ReactMarkdown
                    className="netlify-html  text-white"
                    source={post.about_customer
                      .split(`](assets`)
                      .join(`](/assets`)}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1 pb-6">
            {" "}
            <ReactMarkdown
              className="netlify-html  text-base  text-white"
              source={post.body.split(`](assets`).join(`](/assets`)}
            />
          </div>
        </div>
      </section>
      <section>
        <Img
          className="max-w-6xl mx-auto ImageMove "
          fluid={post.first_image.childImageSharp.fluid}
        />
        <div className="container text-center mx-auto my-10 px-6 py-10  max-w-3xl ">
          <p className="font-bold text-gladior-black text-4xl">Listen</p>
          <p className="text-2xl mb-6 ">{post.listen_title}</p>
          <ReactMarkdown
            className="netlify-html "
            source={post.listen_content.split(`](assets`).join(`](/assets`)}
          />
        </div>
        <Img
          className="max-w-6xl mx-auto "
          fluid={post.second_image.childImageSharp.fluid}
        />
        {post.quote && (
          <blockquote className="relative  text-lg italic  quote bg-dark max-w-5xl py-16 px-6  text-center mx-auto lg:ImageMove lg:px-40">
            <p className="mb-4  text-white">{`“${post.quote.quote}”`}</p>
            <cite className="flex items-center">
              <div className="flex flex-col items-start">
                <span className="mb-1 text-sm italic font-bold text-white">
                  {post.quote.person}, {post.quote.organisation}
                </span>
              </div>
            </cite>
          </blockquote>
        )}
        <div className="container text-center mx-auto mx-auto my-10 px-6 py-10  max-w-3xl ">
          <p className="font-bold text-gladior-black text-4xl">Write</p>
          <p className="text-2xl mb-6">{post.write_title}</p>

          <ReactMarkdown
            className="netlify-html "
            source={post.write_content.split(`](assets`).join(`](/assets`)}
          />
        </div>
        <Img
          className="max-w-6xl mx-auto "
          fluid={post.third_image.childImageSharp.fluid}
        />
        <div className="container text-center mx-auto mx-auto my-10 px-6 py-10  max-w-3xl ">
          <p className="font-bold text-gladior-black text-4xl">Produce</p>
          <p className="text-2xl mb-6">{post.produce_title}</p>
          <ReactMarkdown
            className="netlify-html "
            source={post.produce_content.split(`](assets`).join(`](/assets`)}
          />
        </div>
        <Img
          className="max-w-6xl mx-auto "
          fluid={post.fourth_image.childImageSharp.fluid}
        />
        <div className="container text-center mx-auto mx-auto my-10 px-6 py-10  max-w-3xl ">
          <p className="font-bold text-gladior-black text-4xl">Perform</p>
          <p className="text-2xl mb-6">{post.perform_title}</p>
          <ReactMarkdown
            className="netlify-html  "
            source={post.perform_content.split(`](assets`).join(`](/assets`)}
          />
        </div>
      </section>
    </>
  );
};

WebCasePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const WebCasePage = ({ data }) => {
  const { webcasesYaml: post } = data;

  return (
    <>
      <SEO
        title={post.seo.meta_title}
        description={post.seo.meta_description}
        pathname={post.path}
      />
      <Layout
        subtitle={post.subtitle}
        title={post.title}
        heroBackgroundImage={post.hero_background_image}
      >
        <main>
          <WebCasePageTemplate
            contentComponent={HTMLContent}
            content={post.html}
            post={post}
          />
          <Here2Stay />
        </main>
        <ContactForm />
      </Layout>
    </>
  );
};

WebCasePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WebCasePage;

export const WebCasePageQuery = graphql`
  query WebCasePage($id: String!) {
    webcasesYaml(id: { eq: $id }) {
      about_customer
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      body
      first_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      fourth_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      id
      lead_paragraph
      listen_content
      listen_title
      perform_title
      perform_content
      produce_title
      produce_content
      quote {
        organisation
        person
        quote
      }
      seo {
        meta_description
        meta_title
      }
      second_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      third_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      samenvatting_overview
      services
      subtitle
      templateKey
      title
      write_title
      write_content
    }
  }
`;
